<template>
  <main align="center" justify="center">
    <div class="layout-subcontainer">
      <h1 class="tit-h1">웨이팅확인</h1>

    </div>

  </main>
</template>

<script>
  export default {
    name: "MENU_CAM0700", //name은 'MENU_' + 파일명 조합
    components: {

    },
    metaInfo() {
      return {
        title: "웨이팅확인",
      }
    },
    data() {
      return {

      };
    },

    mounted() {
      this.init();
    },

    methods: {
      async init(){
      },
    },

    computed: {
      initHeaders(){
        return {
        };
      },
      paramData(){
        return {
        };
      }
    },
  };
</script>